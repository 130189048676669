import React from 'react';
import queryString from 'query-string';

import pick from 'lodash/pick';
import transform from 'lodash/transform';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Visa from 'Icons/Visa';
import Mastercard from 'Icons/Mastercard';
import Discover from 'Icons/Discover';
import Amex from 'Icons/Amex';

export function getCcIcon(cardType) {
  switch (cardType) {
    case 'visa':
      return <Visa />;
    case 'mastercard':
      return <Mastercard />;
    case 'discover':
      return <Discover />;
    case 'amex':
      return <Amex />;
  }
}

export function getCookie(name) {
  const cookies = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return cookies ? cookies[2] : null;
}

export function getSpidCidHeader() {
  const isDev = process.env.NODE_ENV === 'development';
  // need a cid and spid in the headers for the fetch to work for dev on localhost. We must check if the re-direct url contains the query to add the headers.
  const query = typeof window !== 'undefined' && queryString.parse(window.location.search);
  if ((isDev || process.env.staging) && query && query.spid && query.cid) {
    document.cookie = 'hasCID=1; Secure';
    document.cookie = `CID=${query.cid}; path=/; Secure`;
    document.cookie = `SPID=${query.spid}; path=/; Secure`;
  }
  const spid = typeof window !== 'undefined' && (query.spid || getCookie('SPID'));
  const cid = typeof window !== 'undefined' && (query.cid || getCookie('CID'));

  return isDev ? { spid: spid, cid: cid } : null;
}

export function handleKeyDown(e, func) {
  e.preventDefault();
  return func();
}

export const errorPageList = ['account', 'checkout', 'scheduling', 'scheduling-mobile', 'update-credit-card', 'reactivate'];

export function generateRandomId(length) {
  return [...Array(length)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
}

/**
 *
 * @param {string | number} phone
 * @returns (123) 456-7890
 */
export const phoneFormatter = (phone) => {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, '');

  // remove leading 1 for numbers including the us country code eg. 1-***-***-***
  if (phone.length == 11 && +phone.slice(0, 1) === 1) {
    phone = phone.slice(1, phone.length);
  }

  //check if number length equals to 10
  if (phone.length == 10) {
    //reformat and return phone number
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }

  return null;
};

/**
 *
 * @param {string} url
 * @returns array [slug, ...]
 */
export const getSlugs = (url) =>
  url
    .split('?')[0]
    .split('/')
    .filter((string) => string !== '');

/**
 *
 * @param {string} url
 * @returns string foo-bar
 */

export const slugify = (string) => {
  return string
    .trim()
    .replace(/\W+/g, '-')
    .toLowerCase();
};

export const scrollToTop = (scrollDuration, topOffset = 0) => {
  const cosParameter = window.scrollY / 2;
  let scrollCount = 0;
  let oldTimestamp = performance.now();
  const step = (newTimestamp) => {
    scrollCount += Math.PI / (scrollDuration / (newTimestamp - oldTimestamp));
    if (scrollCount >= Math.PI) window.scrollTo(0, topOffset);
    if (window.scrollY === topOffset) return;
    const scrollStep = Math.round(cosParameter + cosParameter * Math.cos(scrollCount));
    window.scrollTo(0, scrollStep < topOffset ? topOffset : scrollStep);
    oldTimestamp = newTimestamp;
    window.requestAnimationFrame(step);
  };
  window.requestAnimationFrame(step);
};

export const isOdd = (n) => {
  return Math.abs(n % 2) == 1;
};

export const getPhoneMask = (value) => {
  return value.length === 10 ? '(999) 999-9999' : null;
};

export const luhnCheck = (number) => {
  //remove spaces
  const sanitizedNumber = number.replace(/\s+/g, '');

  const arr = (sanitizedNumber + '')
    .split('')
    .reverse()
    .map((x) => +x);

  // reduce the array every other digit multiply by 2 and if the result is greater than 9
  // add the two together (11 1 + 1 = 2 || 11 - 9 = 2). Add each digit to the sum.
  const sum = arr.reduce((acc, val, i) => (isOdd(i) ? acc + (val * 2 > 9 ? val * 2 - 9 : val * 2) : acc + val), 0);

  // Check the sum mod 10 = 0
  return sum % 10 === 0;
};

export const getMarketFromAddress = (address) => {
  const stateToMarketMap = {
    MO: { id: 'kansas-city', name: 'Kansas City' },
    KS: { id: 'kansas-city', name: 'Kansas City' },
    FL: { id: 'vero-beach', name: 'Vero Beach' },
    PA: { id: 'pittsburgh', name: 'Pittsburgh' },
    AR: { id: 'northwest-arkansas', name: 'Northwest Arkansas' },
    GA: { id: 'atlanta', name: 'Atlanta' },
    AZ: { id: 'phoenix', name: 'Phoenix' },
  };

  // Appropriate error handling should be done wherever this is being called
  return stateToMarketMap[address.state];
};

export const setWPlusQueryCookie = () => {
  // store query params in a cookie with a 3 day exp.
  // pass referrer param if referrer is not walmart.com

  // https://gecgithub01.walmart.com/R-Grocery/wplus-landing-pages/blob/main/src/pages/_app.js#L55-L118

  const queryWhitelist = ['veh', 'adid', 'sourceid', 'cn', 'povid', 'wmlspartner', 'rf'];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  let query = {};

  for (const [key, value] of urlParams) {
    query[key] = value;
  }

  const referrer = document.referrer;

  let queryParams = {};
  let plusParamsCookie = getCookie('plus_params');
  let existingQueryParams = plusParamsCookie ? JSON.parse(plusParamsCookie) : {};

  const lowerQuery = transform(query, function(result, val, key) {
    result[key.toLowerCase()] = val;
  });

  let allowedQueryParams = pick(lowerQuery, queryWhitelist);

  // Need to add any referrer other than walmart.com to the queryParms state object
  // that get used for all external links
  if (referrer) {
    const referrerHostName = new URL(referrer).hostname;
    const cleanReferrer = referrerHostName.replace('www.', '');

    // Omit all walmart.com domains
    if (!cleanReferrer.includes('walmart.com')) {
      allowedQueryParams.rf = cleanReferrer;
    }
  }

  if (!isEmpty(existingQueryParams) && isEmpty(allowedQueryParams)) {
    // Cookies set but no query params, use the cookie
    queryParams = {
      ...existingQueryParams,
    };
  } else if (!isEmpty(existingQueryParams) && !isEqual(existingQueryParams, allowedQueryParams)) {
    // Cookie different from the query params, overwrite with query params
    queryParams = {
      ...allowedQueryParams,
    };
  } else if (!isEmpty(existingQueryParams)) {
    // No cookie plus params, use existing query params,
    queryParams = {
      ...existingQueryParams,
    };
  } else {
    // Settign default cookie
    queryParams = {
      ...allowedQueryParams,
    };
  }

  if (Object.keys(queryParams).length > 0) {
    document.cookie = `plus_params=${JSON.stringify(queryParams)}; maxAge=${60 * 60 * 24 * 3}; path=/; Secure`;
  }

  return queryParams;
};

export const formatAddress = (home) => {
  const { street_1: street1, street_2: street2, city, state, postal_code: postalCode } = home;
  const street = street2 ? `${street1} ${street2}` : street1;

  return `${street}, ${city}, ${state} ${postalCode}`;
};

export const stripWhitespace = (string) => {
  return string.replace(/\s/g, '');
};
